const React = require('react')
const createReactClass = require('create-react-class')

const DatetimeInput = createReactClass({

  getInitialState () {
    return {
      day: '',
      month: '',
      year: new Date().getFullYear(),
      hours: '',
      minutes: ''
    }
  },

  onChange (event) {
    const result = {}
    result[event.target.className] = event.target.value

    this.setState(result, () => {
      const dateString = `${this.state.year}-${this.state.month}-${this.state.day} ${this.state.hours}:${this.state.minutes}`
      const date = moment(dateString)

      if (date.isValid()) {
        return this.props.onChange(date.format('YYYY-MM-DD HH:mm'))
      } else {
        return this.props.onChange(null)
      }
    })
  },

  render () {
    return (
      <div className="field datetime">
        {this.props.label && <label>{this.props.label}</label>}

        <input
          className="day"
          type="text"
          value={this.state.day}
          onChange={this.onChange}
          placeholder="dd"
          maxLength="2"
        />

        <input
          className="month"
          type="text"
          value={this.state.month}
          onChange={this.onChange}
          placeholder="mm"
          maxLength="2"
        />

        <input
          className="year"
          type="text"
          value={this.state.year}
          onChange={this.onChange}
          placeholder="yyyy"
          maxLength="4"
        />

        <input
          className="hours"
          type="text"
          value={this.state.hours}
          onChange={this.onChange}
          placeholder="hh"
          maxLength="2"
        />
        <span>:</span>
        <input
          className="minutes"
          type="text"
          value={this.state.minutes}
          onChange={this.onChange}
          placeholder="mm"
          maxLength="2"
        />
      </div>
    )
  }
})

module.exports = DatetimeInput
