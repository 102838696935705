const React = require('react')
const useSelector = require('react-redux').useSelector

const CustomerSegment = React.memo(({ customer }) => {
  const segment = useSelector(state => state.orm.segments.getById(customer?.segment_id))

  const classNames = ['lg']
  classNames.push(Inflector.parameterize(segment?.name || 'geen'))

  return (
    <div className={`badge ${classNames.join(' ')}`}>
      <span>{segment?.name || I18n.t('javascripts.none')}</span>
    </div>
  )
})

CustomerSegment.displayName = 'CustomerSegment'

module.exports = CustomerSegment
