import React, { memo, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import ReactSelect from 'react-select'
import _ from 'lodash/fp'

import NoSale from 'client/react/scenes/calendar/planner/no_sale'
import Monthpicker from 'client/react/components/inputs/monthpicker'
import Checkbox from 'client/react/components/inputs/checkbox'
import CalendarActions from 'client/redux/actions/calendar_actions'
import productActions from 'client/redux/actions/product'
import NoSaleActions from 'client/redux/actions/no_sale'

const Actions = memo((props) => {
  const { domains, domainId } = props

  const selectOptions = (depots) => {
    const options = []
    depots.forEach((group) => {
      if (group.children) {
        options.push({
          label: group.label,
          options: group.children
        })
      } else {
        options.push(group)
      }
    })
    return options
  }

  const getSelectedDepot = () => {
    const depotId = props.depotId

    for (const depot of props.depots) {
      if (depot.value === depotId) {
        return depot
      }
      if (depot.children) {
        for (const child of depot.children) {
          if (child.value === depotId) {
            return child
          }
        }
      }
    }
  }

  const selectedDomain = useMemo(() => {
    const domain = _.find({ value: domainId }, domains)
    return { label: domain?.label }
  }, [domains, domainId])

  const getAutocompleteOptions = () => {
    if (props.autocompleteResults.size() === 0) {
      return []
    }

    return props.autocompleteResults.map((option) => ({
      value: option.id,
      label: option.name
    }))
  }

  const getQuery = () => {
    if (!props.query) {
      return null
    }

    return { label: props.query }
  }

  const handleKeyDown = useCallback((event) => {
    if (event.key !== 'Enter') {
      return
    }

    props.selectAutocomplete({ label: event.target.value })
  }, [props])

  return (
    <>
      <div className="filter">
        <ReactSelect
          className="react-select-container"
          options={selectOptions(props.depots)}
          value={getSelectedDepot()}
          placeholder={I18n.t('javascripts.planner.select_depot')}
          onChange={props.changeDepotId}
        />
      </div>
      <div className="filter">
        <ReactSelect
          className="react-select-container"
          options={domains}
          value={selectedDomain}
          placeholder={I18n.t('javascripts.planner.select_domain')}
          onChange={props.changeDomainId}
        />
      </div>
      <div className="filter">
        <ReactSelect
          inputId="search-product"
          className="react-select-container react-select-search-product"
          options={getAutocompleteOptions()}
          value={getQuery()}
          onChange={props.selectAutocomplete}
          onInputChange={props.autocomplete}
          onKeyDown={handleKeyDown}
          placeholder={I18n.t('javascripts.planner.search_product')}
          isClearable
        />
      </div>

      {
        props.orderID
          ? (
            <NoSale />
            )
          : (
            <>
              <Monthpicker onChange={props.setPeriod} />
              <span className="filter">
                <Checkbox
                  label={I18n.t('javascripts.planner.show_inactive_products')}
                  onChange={props.setActive}
                />
              </span>
              <span className="filter">
                <Checkbox
                  label={I18n.t('javascripts.planner.show_only_non_rental_products')}
                  onChange={props.setBook}
                />
              </span>
            </>
            )
      }
    </>
  )
})

const mapStateToProps = (state, props) => {
  const calendar = state.calendar

  return {
    depotId: calendar.filters.depot_id,
    query: calendar.autocompleteQuery,
    autocompleteResults: state.orm.products.getAll({ id: calendar.autocompleteIds }),
    orderID: state.planner.orderID,
    domainId: calendar.filters.domain_id
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const getCalendarData = () => {
    const calendarData = {}
    calendarData.query = store.getState().calendar.query
    calendarData.filters = store.getState().calendar.filters
    return calendarData
  }

  const selectAutocomplete = (item) => {
    NoSaleActions.unsetNoSale()

    const query = item?.label
    const calendarData = getCalendarData()

    calendarData.query = query
    calendarData.filters.product_id = item?.value

    CalendarActions.setFilters(calendarData)
    CalendarActions.setAutocompleteQuery(query)
  }

  return {
    selectAutocomplete,
    changeDepotId: (value) => {
      const calendarData = getCalendarData()
      calendarData.filters.depot_id = value ? value.value : 'rental'

      CalendarActions.setFilters(calendarData)
    },
    changeDomainId: (value) => {
      const calendarData = getCalendarData()
      if (value) {
        calendarData.filters.domain_id = value.value
      }

      CalendarActions.setFilters(calendarData)
    },
    setActive: (value) => {
      const calendarData = getCalendarData()
      calendarData.filters.active = !value
      CalendarActions.setFilters(calendarData)
    },
    setBook: (value) => {
      const calendarData = getCalendarData()
      calendarData.filters.book = !value
      CalendarActions.setFilters(calendarData)
    },
    autocomplete: (value) => {
      if (value.length !== 0) {
        CalendarActions.setAutocompleteQuery(value)

        productActions.autocomplete({ query: value }).then((response) => {
          const productIds = value === '' ? [] : response.payload.normalizedResponse.result.products
          CalendarActions.setAutocompleteResults(productIds)
        })
      }
    },
    setPeriod: (date) => {
      CalendarActions.setMonth(date)
    }
  }
}

Actions.displayName = 'Actions'

export default connect(mapStateToProps, mapDispatchToProps)(Actions)
