// React
import React, { Component } from 'react'
import { connect as reduxConnect } from 'react-redux'

import { sortPlannings } from 'mobile/utils/sorting'

class BookedProducts extends Component {
  static displayName = 'BookedProducts'

  constructor(props) {
    super(props)

    this.state = {}
  }

  renderChildren (childPlanning) {
    const { stocks } = this.props
    const stock = stocks.getById(childPlanning.stock_id)

    if (!stock) { return null }

    return (
      <p key={childPlanning.id} className="nested">
        <span className="number">{stock.number}</span>
        <span className="name" title={stock.name}>{stock.name}</span>
      </p>
    )
  }

  renderPlanning (parentPlanning) {
    const { stocks, plannings } = this.props
    const stock = stocks.getById(parentPlanning.stock_id)
    const children = plannings.getAll({ parent_id: parentPlanning.id })

    return (
      <li key={parentPlanning.id} data-stock-name={stock.name} data-product-id={stock.product_id}>
        <p>
          <span className="number">{stock.number}</span>
          <span className="name" title={stock.name}>{stock.name}</span>
        </p>
        {children.map(this.renderChildren.bind(this))}
      </li>
    )
  }

  render () {
    const { stocks, plannings, products } = this.props

    const parents = plannings.getAll({ parent_id: null, invoice: true })
    const sortedPlannings = sortPlannings({ plannings: parents, products, stocks })

    return (
      <div id="order-products" className="divider">
        <div className="title">{I18n.t('javascripts.booked_products.title')}</div>
        <ul>
          {sortedPlannings.map(this.renderPlanning.bind(this))}
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    order: state.orm.orders.getById(props.order_id),
    plannings: state.orm.plannings.getAll({ order_id: props.order_id }),
    stocks: state.orm.stocks,
    products: state.orm.products
  }
}

const mapDispatchToProps = (state, props) => {
  return {}
}

export default reduxConnect(mapStateToProps, mapDispatchToProps)(BookedProducts)
