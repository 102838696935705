import React from 'react';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import _ from 'lodash/fp';

import PhoneClientActions from 'client/redux/actions/phone_client';

const LanguageSelect = () => {
  const locales = useSelector(state => state.phoneClient.locales.toArray());

  const localeToOption = (locale) => ({
    value: locale,
    label: locale.toUpperCase(),
  })

  const setLocales = _.flow(
    _.map('value'),
    PhoneClientActions.setLocales,
  )

  return (
    <div id="phone-bar-locales">
      <ReactSelect
        isMulti
        value={locales.map(localeToOption)}
        onChange={setLocales}
        options={window.locales.map(localeToOption)}
        isClearable={false}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default LanguageSelect;
