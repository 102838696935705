import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash/fp'

import CalendarActions from 'client/redux/actions/calendar_actions'
import { selectPlanningsByOrderId } from 'mobile/redux/selectors/ormSelectors'

import CartProductColumns from './CartProductColumns'
import { groupPlanningsByProductId } from './helpers'

const CartProducts = React.memo((
  {
    cart_products,
    no_sales,
    order_id
  }
) => {
  const plannings = useSelector(state => selectPlanningsByOrderId(state, order_id))

  const plannedQuantity = (cart_product) => {
    const planningsForProduct = plannings.getAll({ product: cart_product.product_id, invoice: true })
    return planningsForProduct.filter((planning) => planning.carts_product_id !== null).size()
  }

  const allPlanned = (cart_product) => plannedQuantity(cart_product) === cart_product.qty

  const hasLimitedAvailability = (cart_product) => {
    return !allPlanned(cart_product) && _.some({ product_id: cart_product.product_id }, no_sales)
  }

  const anyChildrenHaveLimitedAvailability = (cart_product) => {
    return _.some(hasLimitedAvailability, cart_product.children)
  }

  const shouldShowLimitedAvailability = (cart_product) => {
    return hasLimitedAvailability(cart_product) || anyChildrenHaveLimitedAvailability(cart_product)
  }

  const renderChildren = (cart_product) => {
    if (!cart_product.children) {
      return null
    }

    const groupedChildren = groupPlanningsByProductId(cart_product.children)

    return (
      <>
        {_.map((child) => (
          <div key={child.id} className="row accessory-row" data-testid={`accessory-${child.product_id}`}>
            <CartProductColumns
              cart_product={child}
              plannedQuantity={plannedQuantity(child)}
              allPlanned={allPlanned(child)}
              hasLimitedAvailability={hasLimitedAvailability(child)}
            />
          </div>
        ), groupedChildren)}
      </>
    )
  }

  const onClickProduct = (cart_product) => () => {
    const accessoryIds = _.map('product_id', cart_product.children)

    CalendarActions.filterByProduct({
      name: cart_product.product_name,
      product_id: cart_product.product_id,
      optional_accessory_ids: accessoryIds
    })
  }

  return (
    <>
      {cart_products.map((cart_product) => (
        <li
          key={cart_product.id}
          className="container"
          onClick={onClickProduct(cart_product)}
        >
          <div className="row mb-2" data-testid={`product-${cart_product.product_id}`}>
            <CartProductColumns
              cart_product={cart_product}
              plannedQuantity={plannedQuantity(cart_product)}
              allPlanned={allPlanned(cart_product)}
              hasLimitedAvailability={hasLimitedAvailability(cart_product)}
            />
          </div>
          {renderChildren(cart_product)}
          {shouldShowLimitedAvailability(cart_product) && (
            <div className="limited-availability mt-2">
              {I18n.t('javascripts.order.products_sidebar.limited_availability')}
            </div>
          )}
        </li>
      ))}
    </>
  )
})

CartProducts.displayName = 'CartProducts'

export default CartProducts
