import React from 'react'

const Spinner = () => (
  <div style={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '40px',
    height: '40px',
    border: '2px solid white',
    borderRadius: '50%',
    borderTopColor: 'transparent',
    zIndex: 1,
    animation: 'spinner 1000ms infinite linear'
  }} />
)

export default Spinner
