const React = require('react');
const { DropTarget } = require('react-dnd');
const Utils = require('client/utils/utils');

const rowTarget = {
  drop: (props, monitor) => {
    const item = monitor.getItem();
    props.onSwap(item.planning, props.stock);
  },
  canDrop: (props, monitor) => {
    const item = monitor.getItem();
    return Utils.Calendar.canBookStock(props.stock, item.planning.pickup_at, item.planning.return_at);
  },
};

const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    isDragging: monitor.getItemType(),
  };
};

const Row = (props) => {
  const classNames = [];

  if (props.isDragging) {
    classNames.push('dragging');
    if (props.isOver) classNames.push('drag-over');
    if (props.canDrop) classNames.push('drag-can-drop');
    if (props.canDrop === false) classNames.push('drag-cannot-drop');
  }

  return props.connectDropTarget(
    <div className={`gantt-row stock ${props.className} ${classNames.join(' ')}`}>
      <div className="items">
        {props.children}
      </div>
    </div>
  );
};

module.exports = DropTarget('planning', rowTarget, collect)(Row);
