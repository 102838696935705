const React = require('react')
const PropTypes = require('prop-types')
const useSelector = require('react-redux').useSelector

const CustomerSegment = require('client/react/components/customer_segment')

const Customer = React.memo(({ phoneCall }) => {
  const customer = useSelector(state => state.orm.customers.getById(phoneCall.customer_id))

  let className, clickFunction

  if (customer) {
    clickFunction = () => Turbo.visit(`/customers/${customer.id}/orders`)
    className = 'clickable'
  }

  return (
    <div
      id="phone-call-customer"
      className={className}
      onClick={clickFunction}
    >
      {
        phoneCall?.direction === 'internal'
          ? (
            <div className="badge geen">
              <span>{I18n.t('javascripts.phone_bar.internal')}</span>
            </div>
            )
          : (
            <CustomerSegment customer={customer}/>
            )
      }

      <div className="info">
        <span className="customer-name">
          {phoneCall.customer_name}
        </span>
        <span className="phone-number">
          {
            phoneCall?.direction === 'internal'
              ? I18n.t('javascripts.phone_bar.internal_call')
              : phoneCall.number
          }
        </span>
      </div>
    </div>
  )
})

Customer.displayName = 'Customer'
Customer.propTypes = {
  phoneCall: PropTypes.object
}

module.exports = Customer
