const React = require('react')
const PropTypes = require('prop-types')

/* eslint-disable react/jsx-no-bind */
const Dialpad = React.memo(({ onChange }) => {
  const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#']

  return (
    <div id="dialpad">
      {keys.map((key) => (
        <div className="key" key={key}>
          <a onClick={onChange.bind(null, key)}>
            {key}
          </a>
        </div>
      ))}
    </div>
  )
})
/* eslint-enable react/jsx-no-bind */

Dialpad.displayName = 'Dialpad'
Dialpad.propTypes = {
  onChange: PropTypes.func.isRequired
}

module.exports = Dialpad
