const React = require('react')
const createReactClass = require('create-react-class')

const Transport = createReactClass({

  displayName: 'Transport',
  mixins: [PureAwesomeMixin],

  cssClasses () {
    const classes = ['item', 'type-transport']

    if (this.props.transport.pickup_depot_id !== this.props.transport.return_depot_id) {
      classes.push('different-depot')
    }
    classes.push(this.props.transport.status)

    if (this.props.transport.direct) {
      classes.push('direct-transport')
    } else {
      classes.push('normal-transport')
    }

    return classes.join(' ')
  },

  renderDepot (depotId, numDepots, i) {
    const depot = depots[depotId]
    const width = 100 / numDepots

    return (
      <div
        key={depotId}
        className={`depot-transition color ${depot.color_class}`}
        style={{ width: `${width}%`, zIndex: numDepots - i || 1 }}
      >
        <span className="inner">{depot.code}</span>
      </div>
    )
  },

  renderDepots () {
    let numDepots
    const result = []

    if (this.props.plannings.size() > 0) {
      numDepots = this.props.plannings.size() + 1
      this.props.plannings.forEach((planning, i) => {
        result.push(this.renderDepot(planning.pickup_depot_id, numDepots, i))
      })
    } else {
      numDepots = 2
      result.push(this.renderDepot(this.props.transport.pickup_depot_id, numDepots, 0))
    }

    result.push(this.renderDepot(this.props.transport.return_depot_id, numDepots, result.length))
    return result
  },

  render () {
    let icon
    const pickupDepot = depots[this.props.transport.pickup_depot_id]

    if (this.props.transport.direct) {
      icon = 'fa-truck-monster'
    } else {
      icon = 'fa-truck'
    }

    return (
      <div
        className={this.cssClasses()}
        data-orderid={this.props.transport.order_id}
        data-transportid={this.props.transport.id}
      >
        <div className={`item-inner border-color ${pickupDepot.color_class}`}>
          <div className="item-content">
            <i className={`fa ${icon}`} />
          </div>
        </div>
        <div className="item-inner-hover">
          <div className="item-content">
            <div className="depot-transitions">
              {this.renderDepots()}
            </div>
          </div>
        </div>
      </div>
    )
  }
})

module.exports = Transport
