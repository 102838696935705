const React = require('react')
const createReactClass = require('create-react-class')
const ReactSelect = require('react-select').default

const employeeSelectors = require('client/react/selectors/employee')
const EmployeeActions = require('client/redux/actions/employee')
const CustomerActions = require('client/redux/actions/customer')
const UserActions = require('client/redux/actions/user')
const CallbackRequestActions = require('client/redux/actions/callback_request')

const DatetimeInput = require('./inputs/datetime')

/* eslint-disable react/jsx-no-bind */
const CallbackRequestGenerator = createReactClass({
  getInitialState () {
    return {
      scheduled_at: null,
      employee_id: this.props.employeeId,
      reason: ''
    }
  },

  componentDidMount () {
    this.props.fetchUser()
    this.props.fetchCustomer()
    this.props.fetchEmployees()
  },

  sendToServer () {
    return this.props.handleSaveCallbackRequest({
      scheduled_at: this.state.scheduled_at,
      reason: this.state.reason,
      user_id: this.props.userId,
      customer_id: this.props.customerId,
      employee_id: this.state.employee_id,
      number: this.props.user?.phony || this.props.customer?.phony
    })
  },

  handleFormSubmit (event) {
    event.preventDefault()
    return this.sendToServer()
  },

  render () {
    const options = [{ value: null, label: I18n.t('javascripts.callback_request_generator.no_preference') }]
    this.props.employees.map(employee => options.push({ value: employee.id, label: employee.name }))

    return (
      <div id="callback-request-popup" className="modal">
        <div className="popup">
          <div className="inner">
            <div className="content">
              <h1 className="title">
                {I18n.t('javascripts.callback_request_generator.new_callback_request_for', { name: this.props.customer?.name })}
              </h1>

              <div className="contact-person">
                <strong>
                  {I18n.t('javascripts.callback_request_generator.contact_person')}
                </strong> {this.props.user?.name}
              </div>

              <hr className="divider"/>

              <form onSubmit={this.handleFormSubmit}>
                <div className="field">
                  <label>{I18n.t('javascripts.callback_request_generator.employee')}</label>
                  <ReactSelect
                    options={options}
                    name="employee_id"
                    value={options.find(option => option.value === this.state.employee_id)}
                    onChange={selection => this.setState({ employee_id: selection.value })}
                    placeholder={I18n.t('javascripts.callback_request_generator.select_employee')}
                    clearable={false}
                  />
                </div>

                <div className="field">
                  <textarea
                    placeholder={I18n.t('javascripts.callback_request_generator.reason_placeholder')}
                    value={this.state.reason}
                    onChange={event => this.setState({ reason: event.target.value })}
                  />
                </div>

                <DatetimeInput
                  label={I18n.t('javascripts.callback_request_generator.plan_on')}
                  onChange={value => this.setState({ scheduled_at: value })}
                />
              </form>
            </div>

            <div className="buttons">
              <a
                className="button"
                href="#"
                onClick={this.props.close}
              >
                {I18n.t('javascripts.close')}
              </a>
              <a
                className="button green button_yes"
                href="#"
                onClick={this.handleFormSubmit}
              >
                {I18n.t('javascripts.save')}
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
})
/* eslint-enable react/jsx-no-bind */

const mapStateToProps = (state, props) => {
  const employees = employeeSelectors.sortedByName(state.orm.employees)
  const customer = state.orm.customers.getById(props.customerId)
  const user = state.orm.users.getById(props.userId)

  return {
    customer,
    user,
    employees
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const close = () => {
    setTimeout(props.root.unmount)
    const node = $('#react-popup')[0]
    $(node).remove()
    return $('.body-container').append("<div id='react-popup'></div>")
  }

  return {
    fetchUser () {
      if (props.userId) {
        return UserActions.fetch({ id: props.userId })
      }
    },

    fetchCustomer () {
      if (props.customerId) {
        return CustomerActions.fetch({ id: props.customerId })
      }
    },

    fetchEmployees () {
      return EmployeeActions.fetchAll()
    },

    close () {
      return close()
    },

    handleSaveCallbackRequest (values) {
      return CallbackRequestActions.create({ attributes: values }).then(() => {
        return close()
      })
    }
  }
}

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(CallbackRequestGenerator)
