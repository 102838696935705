const React = require('react')
const createReactClass = require('create-react-class')

const CallbackRequestActions = require('client/redux/actions/callback_request')
const CustomerSegment = require('client/react/components/customer_segment')
const { twilioCall } = require('./utils')

/* eslint-disable react/jsx-no-bind */
const CallbackRequests = createReactClass({
  render () {
    return (
      <div id="callback-requests">
        {this.props.callbackRequests.size() === 0 && (
          <div className="cleanstate">
            <p>
              {I18n.t('javascripts.phone_bar.no_callback_requests')}
            </p>
          </div>
        )}
        <ul className="list">
          {
            this.props.callbackRequests.map(callbackRequest => {
              const customer = store.getState().orm.customers.getById(callbackRequest.customer)
              const domain = this.props.domains.getById(callbackRequest.domain_id)

              return (
                <li key={callbackRequest.id}>
                  <CustomerSegment customer={customer}/>

                  <div className="content">
                    <span className={`customer-name customer_status status ${customer?.status?.color}`}>
                      {callbackRequest.customer_name}
                    </span>

                    <div className="locale-domain"><span
                      className="domain">{domain?.name}</span>
                      <span className="locale">{callbackRequest.locale?.toUpperCase()}</span>
                    </div>
                    <div className="reason">{callbackRequest.reason}</div>
                    {callbackRequest.scheduled_at && (
                      <div className="scheduled-at">
                        <i className="fa fa-clock-o"/>
                        {moment(callbackRequest.scheduled_at).format('DD-MM-YYYY HH:mm')}
                      </div>
                    )}
                    <a
                      className="button small"
                      onClick={this.props.call.bind(null, callbackRequest.number)}
                    ><i className="fa fa-phone"/>
                      {I18n.t('javascripts.phone_bar.call')}
                    </a>
                    {customer && (
                      <a
                        className="button small"
                        href={`/customers/${customer.id}/orders`}
                      >
                        <i className="fa fa-calendar-check-o" />
                        {I18n.t('javascripts.phone_bar.view_reservations')}
                      </a>
                    )}
                    <a
                      className="button small green"
                      onClick={this.props.complete.bind(null, callbackRequest.id)}
                    >
                      <i className="fa fa-check"/>
                      {I18n.t('javascripts.phone_bar.to_check')}
                    </a>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
    )
  }
})
/* eslint-enable react/jsx-no-bind */

const mapStateToProps = (state, _props) => {
  return {
    domains: state.orm.domains
  }
}

const mapDispatchToProps = (_dispatch, _props) => {
  return {
    call (number) {
      return twilioCall({ To: number })
    },

    complete (id) {
      const result = confirm('Zeker weten?')

      if (result) {
        return CallbackRequestActions.update({ id, attributes: { done: true } })
      }
    }
  }
}

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(CallbackRequests)
