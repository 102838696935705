import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash/fp'

import {
  selectProducts,
  selectPlanningsByOrderId,
  selectStocks
} from 'mobile/redux/selectors/ormSelectors'
import CalendarActions from 'client/redux/actions/calendar_actions'

import { groupPlanningsByProductId } from './helpers'

const PlanningProducts = React.memo((
  {
    order_id
  }
) => {
  const plannings = useSelector(state => selectPlanningsByOrderId(state, order_id))
  const products = useSelector(selectProducts)
  const stocks = useSelector(selectStocks)

  const parents = plannings.getAll({ parent_id: null, invoice: true, carts_product_id: null })
  const groupedPlannings = groupPlanningsByProductId(parents.toJS())
  const sortedPlannings = _.sortBy((planning) => {
    const product = products.getById(planning.product_id)
    if (!product) {
      return ''
    }
    return product.name
  }, groupedPlannings)

  const renderChild = (child) => {
    const product = products.getById(child.product_id)

    return (
      <div key={child.id} className="row accessory-row" data-testid={`accessory-${child.product_id}`}>
        <span className="col-2 pl-0 pr-0 mr-0 text-center"/>
        <span className="name col pr-1 pl-0" title={product.name}>
          {product.name}
        </span>
        <span className="quantities col-3 pl-0 text-success">
          {child.qty}
        </span>
      </div>
    )
  }

  const onClickProduct = (stock) => () => {
    CalendarActions.filterByProduct({
      name: stock.name,
      product_id: stock.product_id
    })
  }

  const renderPlanning = (planning) => {
    const stock = stocks.getById(planning.stock_id)
    const children = plannings.getAll({ parent_id: planning.id })
    const groupedChildren = groupPlanningsByProductId(children.toJS())

    return (
      <li
        key={planning.id}
        className="container"
        onClick={onClickProduct(stock)}
      >
        <div className="row mb-2" data-testid={`product-${stock.product_id}`}>
          <span className="col-2 pl-0 pr-0 mr-0 text-center"></span>
          <span className="name col pr-1 pl-0" title={stock.name}>
            {stock.name}
          </span>
          <span className="quantities col-3 pl-0 text-success">
            {planning.qty}
          </span>
        </div>
        {_.map(renderChild, groupedChildren)}
      </li>
    )
  }

  return (
    <>
      {_.map(renderPlanning, sortedPlannings)}
    </>
  )
})

PlanningProducts.displayName = 'PlanningProducts'

export default PlanningProducts
