const React = require('react')
const useEffect = require('react').useEffect
const moment = require('moment')

const CalendarActions = require('client/redux/actions/calendar_actions')
const Calendar = require('client/react/components/calendar/calendar')

const CalendarScene = React.memo((props) => {
  useEffect(() => {
    CalendarActions.initialize(moment())
  }, [])

  return <Calendar {...props} />
})

CalendarScene.displayName = 'CalendarScene'

module.exports = CalendarScene
