import { map, groupBy } from 'lodash'

const groupedDepots = (depots) => {
  const shops = depots.filter(depot => depot.store_type === 'shop')
  const pickups = depots.filter(depot => depot.store_type === 'pup')
  const nonRentals = depots.filter(depot => depot.depot_type === 'repair')

  return {
    shops,
    pickups,
    nonRentals
  }
}

const groupDepots = (depots) => {
  const grouped = groupBy(depots, (depot) => depot.domain.country_name)

  return map(grouped, (group, groupName) => {
    return { domain: groupName, ...groupedDepots(group) }
  })
}

export { groupDepots }
