const React = require('react')
const createReactClass = require('create-react-class')

const PhoneClientActions = require('client/redux/actions/phone_client')
const Timer = require('client/react/components/timer')
const Redirector = require('./redirector')
const Dialpad = require('./dialpad')

const CallControls = createReactClass({
  render () {
    return (
      <div id="call-controls">
        <div id="call-timer">
          <Timer />
        </div>
        <div className="buttons">
          {
            this.props.muted
              ? (
                <a className="button button-4" onClick={this.props.unmute}>
                  <i className="fa fa-microphone"/>
                </a>
                )
              : (
                <a className="button button-4" onClick={this.props.mute}>
                  <i className="fa fa-microphone-slash"/>
                </a>
                )
          }
          <a className={`button button-4 ${this.props.redirectClass}`} onClick={this.props.redirect}>
            <i className="fa fa-share"/>
          </a>
          <a className="button button-4" onClick={this.props.toggleDialer}>
            <i className="fa fa-th"/>
          </a>
          <a className="button red button-4" onClick={this.props.hangup}>
            <i className="fa fa-phone hangup"/>
          </a>
        </div>

        {
          this.props.status === 'redirecting' && (
            <>
              <br/>
              <Redirector phoneCall={this.props.phoneCall} employeeId={this.props.employeeId}/>
            </>
          )
        }

        <div className="buttons last">
          <a
            className="button full new-callback-request"
            data-customer-id={this.props.customerId} data-user-id={this.props.userId}
            data-number={this.props.number}
            data-employee-id={this.props.employeeId}
          >
            {I18n.t('javascripts.phone_bar.new_callback_request')}
          </a>
        </div>

        {this.props.dialer && <Dialpad onChange={this.props.sendDigit} />}
      </div>
    )
  }
})

const mapStateToProps = (state, props) => {
  let redirectClass
  const client = state.phoneClient
  const external = props.phoneCall?.direction !== 'internal'

  if (client.status === 'redirecting') {
    redirectClass = 'open'
  } else if (external && client.status !== 'redirecting' && props.phoneCall?.sid) {
    redirectClass = ''
  } else {
    redirectClass = 'disabled'
  }

  return {
    status: client.status,
    employeeId: client.employee_id,
    userId: props.phoneCall?.user_id,
    customerId: props.phoneCall?.customer_id,
    number: props.phoneCall?.number,
    muted: state.phoneClient.muted,
    redirectClass,
    dialer: client.dialer
  }
}

const mapDispatchToProps = (_dispatch, _props) => {
  return {
    toggleDialer () {
      return PhoneClientActions.setDialer(!store.getState().phoneClient.dialer)
    },

    mute () {
      return PhoneClientActions.mute()
    },

    unmute () {
      return PhoneClientActions.unmute()
    },

    hangup () {
      return Twilio.Device.disconnectAll()
    },

    redirect (event) {
      if (event.target.classList.contains('disabled')) {
        return
      }
      return PhoneClientActions.setStatus('redirecting')
    },

    sendDigit (digit) {
      return Twilio.Device.activeConnection()?.sendDigits(`${digit}w`)
    }
  }
}

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(CallControls)
