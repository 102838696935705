const React = require('react')
const createReactClass = require('create-react-class')

const ExportActions = require('client/redux/actions/export')
const PopupTrigger = require('client/react/components/popup/popup_trigger')

/* eslint-disable react/no-string-refs */
const ExportPopup = createReactClass({
  componentDidUpdate (prevProps) {
    if (!prevProps.available && this.props.available) {
      return delay(100, () => {
        return this.refs.downloadLink?.click?.()
      })
    }
  },

  renderExportBusy () {
    return (
      <div>
        {I18n.t('javascripts.exporting')}
        <div className="loading"><i className="fa fa-spinner" /></div>
      </div>
    )
  },

  renderExportAvailable () {
    return (
      <div>
        {I18n.t('javascripts.exporting_done')}
        <a
          ref="downloadLink"
          className="download-link"
          target="_blank"
          href={this.props.export.file.url}
          download={this.props.export.file.url}
          rel="noreferrer"
        >
          {I18n.t('javascripts.exporting_download_link')}
        </a>
      </div>
    )
  },

  render () {
    return (
      <div className="download-popup">
        <a
          id="close"
          href="#"
          className="fa fa-close"
          onClick={this.props.onCancel}
        />
        <div id="body">
          {!this.props.available && this.renderExportBusy()}
          {this.props.available && this.renderExportAvailable()}
        </div>
      </div>
    )
  }
})
/* eslint-enable react/no-string-refs */

// Button to export data. Will render a popup as well because it's triggered in a background process.
//
// @example
//   <ExportButton label="Export to CSV" model="Customer" format="csv" params={} />
//
const ExportButton = createReactClass({
  renderPopup () {
    return (
      <ExportPopup
        started={this.props.started}
        completed={this.props.completed}
        available={this.props.available}
        export={this.props.export}
      />
    )
  },

  render () {
    return (
      <PopupTrigger
        component={this.renderPopup()}
        onTrigger={this.props.handleClick}
      >
        <a href="#" className="button">
          {this.props.label || 'Export'}
        </a>
      </PopupTrigger>
    )
  }
})

const mapStateToProps = (state, _props) => {
  const exportRecord = state.orm.exports.getById(state.export.exportID)

  return {
    started: state.export.started,
    completed: state.export.completed,
    available: state.export.completed && exportRecord,
    export: exportRecord
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleClick () {
      return ExportActions.export({ model: props.model, format: props.format, params: props.params }).then(action => {
        const jobID = action.payload.response.job_id
        const exportID = action.payload.response.export_id

        return Utils.Job.onComplete(jobID, () => {
          return ExportActions.completed(exportID)
        })
      })
    }
  }
}

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ExportButton)
