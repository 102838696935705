export default (context) => {
  const components = {}

  for (const key of context.keys()) {
    const componentName = key.match(/([^/.]+)\.(.*)/)[1]
    const component = context(key)

    components[componentName] = component.default || component
  }

  return components
}
