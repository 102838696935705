const React = require('react')
const createReactClass = require('create-react-class')

/* eslint-disable react/jsx-no-bind */

// React Component: Render a single Gantt stock topic
const Stock = createReactClass({
  displayName: 'Stock',

  cssClasses () {
    const classes = ['gantt-row stock']

    if (this.planningParentStockIds().includes(this.props.stock.id)) {
      classes.push('optional_accessory')
    }

    if (!this.props.stock.active) {
      classes.push('inactive')
    }

    if (!this.props.stock.can_book) {
      classes.push('cannot_book')
    }

    return classes.join(' ')
  },

  planningParentStockIds () {
    const orderId = this.props.order?.id
    return this.props.plannings.map((planning) => {
      if (planning.parent_id && orderId && planning.order_id === orderId) {
        return planning.stock_id
      }
      return null
    })
  },

  renderActions () {
    if (this.props.actions != null) {
      return this.props.actions(this.props.stock)
    }
  },

  renderIcon () {
    if (this.props.stock.in_transport) {
      return <i className="fa fa-truck" title="In transport" />
    } else if (this.props.stock.can_book === false) {
      return <i className="fa fa-ban" title="Not bookable" />
    } else {
      switch (this.props.stock.inventory_status) {
        case 'ready_for_pickup':
          return <i className="fa fa-archive" title="Ready for pickup" />
        case 'picked_up':
          return <i className="fa fa-times" title="Picked up" />
        case 'returned':
          return <i className="fa fa-arrow-left" title="Returned" />
        case 'ready':
          return <i className="fa fa-check" title="Ready2Roll" />
      }
    }
  },

  render () {
    const depot = depots[this.props.stock.depot_id]

    return (
      <div className={this.cssClasses()}>
        <div className="topic stock">
          {this.renderActions()}
          <div className={`status color ${depot.color_class}`}>
            {this.renderIcon()}{depot.code}
          </div>
          <a
            className="vcard-link"
            data-type="stock"
            data-id={this.props.stock.id}
            data-testid="stock-vcard-link"
          >
            <i className="fa fa-eye" />
          </a>
          <span
            className="number"
            onClick={this.props.onClick.bind(null, this.props.stock)}
          >
            {this.props.stock.number}
          </span>
          <div
            className="name"
            title={this.props.stock.name}
            onClick={this.props.onClick.bind(null, this.props.stock)}
          >
            {this.props.stock.name}
          </div>
        </div>
      </div>
    )
  }
})
/* eslint-enable react/jsx-no-bind */

const mapStateToProps = (state, props) => {
  return {
    plannings: state.orm.plannings.getAll({ id: props.stock.plannings })
  }
}

module.exports = ReactRedux.connect(mapStateToProps)(Stock)
