import React from 'react';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import PhoneClientActions from 'client/redux/actions/phone_client';

const DomainSelect = (props) => {
  const domain = useSelector(state => (
    props.domains.getById(state.phoneClient.domain_id) || props.domains.getFirst()
  ));

  const options = props.domains.toArray().map(domain => ({
    value: domain.id,
    label: domain.name
  }));

  const handleChange = (option) => PhoneClientActions.setDomain(option.value);

  return (
    <div id="phone-bar-domain">
      <ReactSelect
        value={{ value: domain.id, label: domain.name }}
        options={options}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleChange}
      />
    </div>
  );
};

export default DomainSelect;
