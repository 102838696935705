const React = require('react')
const createReactClass = require('create-react-class')

/* eslint-disable react/jsx-no-bind */
const Tagger = createReactClass({
  elementRef: React.createRef(),

  getInitialState () {
    return {
      initialized: false,
      tagging: false,
      immutableTags: Immutable.List(this.props.tags)
    }
  },

  componentDidMount () {
    this.onMount()

    // Close tag selecting when clicking outside of the element
    clickOutside($(this.elementRef.current), this.onHide)
  },

  componentDidUpdate (prevProps, prevState) {
    if (prevState.initialized && prevState.immutableTags !== this.state.immutableTags) {
      this.handleSave()
    }
  },

  getTags () {
    return this.state.immutableTags.toJS()
  },

  onMount () {
    this.setState({ initialized: true })
  },

  onHide () {
    this.setState({ tagging: false })
  },

  onToggle () {
    this.setState({ tagging: !this.state.tagging })
  },

  onAddTag (tag) {
    this.setState({ immutableTags: this.state.immutableTags.push(tag) })
  },

  onRemoveTag (tag) {
    this.setState({ immutableTags: this.state.immutableTags.delete(this.state.immutableTags.indexOf(tag)) })
  },

  handleToggle (event) {
    event.preventDefault()
    return this.onToggle()
  },

  isAdded (tag) {
    return this.getTags().includes(tag)
  },

  handleAdd (tag) {
    return this.onAddTag(tag)
  },

  handleRemove (tag) {
    return this.onRemoveTag(tag)
  },

  handleSave () {
    if (!this.props.new_record) {
      return $(this.elementRef.current).parents('form').submit()
    }
  },

  handleKeyPress (event) {
    if (event.keyCode === 13) {
      event.preventDefault()

      const input = $(event.target)
      const tag = input.val()

      this.handleAdd(tag)
      return input.val('')
    }
  },

  renderTags () {
    return this.getTags().map((tag) => (
      <div key={tag} className="tag selected" title={tag}>
        <div className="inner">
          {tag}
          <a
            className="delete-tag fa fa-times"
            onClick={this.handleRemove.bind(null, tag)}
          />
        </div>
      </div>
    ))
  },

  renderAddTags () {
    if (!this.state.tagging) {
      return
    }

    const availableTags = this.props.available_tags.map((tag) => {
      if (this.isAdded(tag)) {
        return null
      }

      return (
        <div
          key={tag}
          className="tag clickable"
          title={tag}
          onClick={this.handleAdd.bind(null, tag)}
        >
          <div className="inner">{tag}</div>
        </div>
      )
    })

    return (
      <div className="select-tag">
        {
          this.props.can_create && (
            <input
              type="text"
              onKeyDown={this.handleKeyPress}
              placeholder={I18n.t('javascripts.tag_add')}
            />
          )
        }
        {availableTags}
      </div>
    )
  },

  render () {
    return (
      <div className="tag-list" ref={this.elementRef}>
        <input
          type="hidden"
          name={`${this.props.type}[tag_list]`}
          value={this.getTags().join(', ')}
        />
        {this.renderTags()}
        <a
          className="add-tag"
          onClick={this.handleToggle}
          href="#"
        >
          <i className="fa fa-plus" />Tag
        </a>
        {this.renderAddTags()}
      </div>
    )
  }
})
/* eslint-enable react/jsx-no-bind */

module.exports = Tagger
