const React = require('react')
const useSelector = require('react-redux').useSelector

const NoSaleActions = require('client/redux/actions/no_sale')

/* eslint-disable react/jsx-no-bind */
const NoSale = React.memo(() => {
  const calendar = useSelector(state => state.calendar)
  const noSale = useSelector(state => state.orm.no_sales.getById(state.noSale.id))
  const product = useSelector(state => state.orm.products.getById(calendar.filters.product_id))
  const alternatives = useSelector(state => state.orm.products.getAll({ id: noSale?.products }))

  const buttonClass = calendar.filters.product_id ? 'enabled' : 'disabled'
  const productId = calendar.filters.product_id

  const register = (noSale, productId) => {
    if (noSale) {
      NoSaleActions.setNoAlternative()
    } else {
      NoSaleActions.register(productId)
    }
  }

  const findAlternative = (product) => {
    NoSaleActions.findAlternative(product)
  }

  return (
    <div className="no-sale">
      <div className="autocomplete" style={{ display: 'inline-block' }}>
        <a
          className={`button red ${buttonClass}`}
          onClick={register.bind(null, noSale, productId)}
        >
          {I18n.t('javascripts.planner.sold_no')}
        </a>
        {noSale && (
          <ul>
            <li className="note">
              {alternatives.size()
                ? I18n.t('javascripts.planner.alternatives')
                : I18n.t('javascripts.planner.no_alternatives_found')}
            </li>
            {alternatives.map((alternative) => (
              <li
                onClick={findAlternative.bind(null, alternative)}
                key={alternative.id}
              >
                {alternative.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      {product?.name && (
        <strong style={{ width: 200, display: 'inline-block', verticalAlign: 'middle' }}>
          {product.name}
        </strong>
      )}
    </div>
  )
})
/* eslint-enable react/jsx-no-bind */

NoSale.displayName = 'NoSale'

module.exports = NoSale
