// React
import React, { Component } from 'react'
// import PropTypes from 'prop-types'

// Libraries

// Components

// Shared

/**
 * Specify order pickup and return time and location.
 *
 * @example
 *   <LocationDateBadge
 *     date="Do 11-07-19"
 *     time="08:30 - 11:00"
 *     depot={depot}
 *     active
 *   />
 */

class LocationDateBadge extends Component {
  static displayName = 'LocationDateBadge'

  static propTypes = {}

  render () {
    const { date, time, active, depot, onClick } = this.props

    return (
      <div className={`location-date-badge ${active ? 'location-date-badge--active' : ''}`} onClick={onClick}>
        <div className={`location-date-badge__location color ${depot.color_class}`}>{depot.code}</div>
        <div>
          <div className="location-date-badge__date">{date}</div>
          <div className="location-date-badge__time">{time}</div>
        </div>
      </div>
    )
  }
}

export default LocationDateBadge
