const React = require('react')
const createReactClass = require('create-react-class')

// Positions item in viewport.
//
// @example
//   <Item from="01-01-2016 09:00" till="04-01-2016" periodFrom="01-01-2016" periodTill="01-02-2016">
//     Render something nifty here!
//   </Item>
//
const Item = createReactClass({

  displayName: 'Item',
  mixins: [PureAwesomeMixin],

  render () {
    const periodFrom = moment(this.props.periodFrom)
    const periodTill = moment(this.props.periodTill)

    const from = moment(this.props.from)
    const till = moment(this.props.till)

    if (Utils.Calendar.isOverlapping(periodFrom, periodTill, from, till)) {
      const left = Utils.Calendar.calculatePeriodOffset(periodFrom, periodTill, from)
      const right = Utils.Calendar.calculatePeriodOffset(periodFrom, periodTill, till)
      const width = right - left

      const style = {
        left: `${left}%`,
        width: `${width}%`
      }

      return <div className="item-wrapper" style={style}>{this.props.children}</div>
    } else {
      return null
    }
  }
})

module.exports = Item
