const React = require('react');
const { DragLayer } = require('react-dnd');
const Planning = require('./../item/planning');

function getItemStyles(props) {
  const initialOffset = props.initialOffset;
  const currentOffset = props.currentOffset;
  const diffOffset = props.diffOffset;

  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  // eslint-disable-next-line id-length
  const x = initialOffset.x;
  // eslint-disable-next-line id-length
  let y = currentOffset.y;

  // Snap to rows
  y = initialOffset.y - 2 + Math.round(diffOffset.y / 34) * 34;

  return {
    left: x,
    top: y,
    width: document.getElementById(`planning-${props.item.planning.id}`).offsetWidth,
  };
}

function CustomDragLayer(props) {
  if (!props.isDragging) {
    return null;
  }

  const layerStyle = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
  };

  return (
    <div style={layerStyle}>
      <div className="item-wrapper" style={getItemStyles(props)}>
        <Planning planning={props.item.planning} className="dragging" />
      </div>
    </div>
  );
}

module.exports = DragLayer((monitor) => ({
  item: monitor.getItem(),
  isDragging: monitor.isDragging(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  diffOffset: monitor.getDifferenceFromInitialOffset(),
  itemType: monitor.getItemType(),
}))(CustomDragLayer);
