const React = require('react')
const createReactClass = require('create-react-class')

const CalendarActions = require('client/redux/actions/calendar_actions')

const Units = require('./period/units')
const Viewport = require('./viewport')
const Stock = require('./topic/stock')
const SwapStockConfirmation = require('./swap_stock_confirmation')

/* eslint-disable react/jsx-no-bind */
const Calendar = createReactClass({
  displayName: 'Calendar',
  mixins: [PureAwesomeMixin],

  render () {
    if (!this.props.from || !this.props.till) {
      return <div />
    }

    return (
      <div className="gantt">
        <div className="gantt-sidebar">
          <div className="header">
            <div className={`zooms ${this.props.zoom}`}>
              <a className="monthly" onClick={this.props.onZoom.bind(null, 'months')}>
                {I18n.t('javascripts.calendar.month')}
              </a>
              <a className="weekly" onClick={this.props.onZoom.bind(null, 'weeks')}>
                {I18n.t('javascripts.calendar.week')}
              </a>
              <a className="daily" onClick={this.props.onZoom.bind(null, 'days')}>
                {I18n.t('javascripts.calendar.day')}
              </a>
            </div>
          </div>
          <div className="gantt-rows">
            {this.props.stock.map(stock => (
              <Stock
                key={stock.id}
                stock={stock}
                onClick={this.props.onClickStock}
                depots={this.props.depots}
                order={this.props.order}
                actions={this.props.stockActions}
              />
            ))}
          </div>
        </div>

        <div className="gantt-viewport has-sidebar">
          <div className="gantt-period-units">
            <Units
              from={this.props.from}
              till={this.props.till}
              type={this.props.zoom}
              openingTimes={this.props.openingTimes}
              order={this.props.order}
            />
          </div>

          <div className="gantt-scroll">
            <div
              className="gantt-scroller gantt-scroll-left"
              onClick={this.props.onPrevPeriod}
            >
              <i className="fa fa-angle-left" />
            </div>
            <div
              className="gantt-scroller gantt-scroll-right"
              onClick={this.props.onNextPeriod}
            >
              <i className="fa fa-angle-right" />
            </div>
          </div>

          <Viewport
            center={this.props.center}
            from={this.props.from}
            till={this.props.till}
            zoom={this.props.zoom}
            order={this.props.order}
            stock={this.props.stock}
            planning={this.props.planning}
            transports={this.props.transports}
            onScrollToBottom={this.props.moreStock}
            canSwap={this.props.canSwap}
            onSwap={this.props.onSwap}
            orderID={this.props.orderID}
          >
            {this.props.children}
          </Viewport>

          {this.props.loading && (
            <div className="gantt-loader">
              <span>
                <i className="rotating fa fa-circle-o-notch" />
              </span>
            </div>
          )}
        </div>

        {this.props.swapping && (
          <SwapStockConfirmation
            {...this.props.swapStock}
            onCheckMargin={this.props.onCheckMargin}
            onCancel={this.props.onCancelSwap}
            onConfirm={this.props.onConfirmSwap}
          />
        )}
      </div>
    )
  }
})

const mapStateToProps = (state, props) => {
  const { zoom } = state.calendar
  const { from } = state.calendar
  const { till } = state.calendar
  const stockIDs = state.calendar.stockIDs.toArray()

  return {
    loading: state.calendar.loading,
    center: state.calendar.center,
    zoom,
    from,
    till,
    order: state.orm.orders.getById(props.orderID),
    stock: state.orm.stocks.getById(stockIDs),
    planning: state.orm.plannings.getAll({ stock_id: stockIDs }),
    transports: state.orm.transports.getAll({ stock_id: stockIDs }),
    canSwap: props.permissions?.swap_stock || false,
    swapping: state.calendar.swapStock.planningID != null,
    swapStock: state.calendar.swapStock.toJS(),
    openingTimes: state.orm.opening_times
  }
}
/* eslint-enable react/jsx-no-bind */

const mapDispatchToProps = (dispatch, props) => {
  return {
    onZoom (zoom) {
      return CalendarActions.setZoom(zoom)
    },
    onPrevPeriod () {
      return CalendarActions.prevPeriod()
    },
    onNextPeriod () {
      return CalendarActions.nextPeriod()
    },
    moreStock () {
      return CalendarActions.moreStock()
    },
    onClickStock (stock) {
      return CalendarActions.filterByProduct(stock)
    },
    onSwap (planning, stock) {
      CalendarActions.visuallySwap(planning, stock)

      if (planning.getStock().in_transport) {
        // Stock is being transported
        return confirmPopup(I18n.t('javascripts.transported_product'), {
          callbacks: {
            yes () {
              return CalendarActions.askSwap(planning, stock)
            },
            no () {
              return CalendarActions.cancelSwap(planning)
            }
          }
        })
      } else if (planning.contract_printed) {
        // Contract already printed, this displays the stock number
        return confirmPopup(I18n.t('javascripts.printed_contract'), {
          callbacks: {
            yes () {
              return CalendarActions.askSwap(planning, stock)
            },
            no () {
              return CalendarActions.cancelSwap(planning)
            }
          }
        })
      } else {
        return CalendarActions.askSwap(planning, stock)
      }
    },
    onCancelSwap () {
      return CalendarActions.cancelSwap()
    },
    onConfirmSwap (planning, stock) {
      return CalendarActions.confirmSwap(planning, stock)
    },
    onCheckMargin (orderID) {
      return CalendarActions.fetchOrderMargin(orderID)
    }
  }
}

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Calendar)
