import React from "react"
import { connect as reduxConnect } from "react-redux"

import AccessoryStockPlanningsActions from "client/redux/actions/AccessoryStockPlanningsActions"
import { times } from "lodash"

const mapStateToProps = (state, props) => {
  return {
    plannings: state.orm.plannings.getAll({ order_id: props.order.id }),
    product_accessories: state.orm.product_accessories,
    stocks: state.orm.stocks
  }
}

/* eslint-disable react/jsx-no-bind */
@reduxConnect(mapStateToProps)
export default class AccessoryTooltip extends React.Component {
  static displayName = "AccessoryTooltip"

  constructor(props) {
    super(props)

    this.state = {
      visible: false
    }

    this.elementRef = React.createRef()
  }

  handleToggle() {
    this.setState({ visible: !this.state.visible });
  }

  handleClose() {
    this.setState({ visible: false });
    this.props.onClose();
  }

  componentDidMount() {
    // If there is a GanttRow present, add event listener to hide the tooltip
    const ganttStockRow = this.elementRef.current?.closest(".gantt-row")

    if (ganttStockRow) {
      ganttStockRow.addEventListener("mouseleave", this.handleClose.bind(this))
    }
  }

  addAccessory(orderId, planningId, accessoryId) {
    AccessoryStockPlanningsActions.create({
      order_id: orderId,
      accessory_ids: [accessoryId],
      parent_id: planningId
    }).catch((error) => {
      Flash.alert(error.error_message)
    })
  }

  removeAccessory(orderId, accessoryId) {
    AccessoryStockPlanningsActions.delete({
      order_id: orderId,
      id: accessoryId
    }).catch((error) => {
      Flash.alert(error.error_message)
    })
  }

  productHasAccessoriesPlanned() {
    const { planning, plannings } = this.props

    // Check if there is a planning
    if (planning === undefined) { return false }

    // Check if the planning is an accessory.
    return plannings.getAll({ parent_id: planning?.id }).size() > 0
  }

  renderPlanLinks(accessory) {
    const { planning, plannings, stocks } = this.props

    // If there is no stock_planning, we cannot add the accessory so the buttons are not displayed.
    if (!planning) { return }

    // Retrieve all the booked stock ids, based on the accessory id and check if the planning (main product)
    // has this accessory booked in.
    const stockId = stocks.getAll({ product_id: accessory.id }).pluck("id")
    const bookedStockIds = plannings.getAll({ stock_id: stockId, order_id: planning.order_id }).pluck("stock_id")
    const childPlannings = plannings.getAll({ parent_id: planning.id, stock_id: bookedStockIds })
    const isBooked = !!childPlannings.toArray()[accessory.index]

    // Check if the accessory is already planned in, show correct button
    if (!isBooked) {
      return (
        <a className="plan-stock fa fa-plus"
           onClick={this.addAccessory.bind(null, planning.order_id, planning.id, accessory.id)}>
        </a>
      )
    } else {
      return(
        <a className="unplan-stock fa fa-times"
           onClick={this.removeAccessory.bind(null, planning.order_id, accessory.id)}>
        </a>
      )
    }
  }

  renderAccessoryList(accessories, type) {
    const title = (type === 'optional') ? I18n.t('javascripts.accessories_optional') : I18n.t('javascripts.accessories_fixed')

    if(accessories.length > 0) {
      return(
        <div className="accessory-list">
          <span className="title">{title}</span>

          <ul className={type}>
            {accessories.map((accessory, index) => (
              <li key={index}>
                <div className="row-tooltip-name">{accessory.name}</div>

                <div className="row-tooltip-actions">
                  {this.renderPlanLinks(accessory)}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )
    }
  }

  render() {
    const optionalPickupAccessories = []
    const fixedPickupAccessories = []

    // Find the optionalAccessories
    const optionalAccessories = this.props.product_accessories.getAll({
      product_id: this.props.stock.product_id,
      accessory_type: "optional_pickup"
    })

    // Find the fixedAccessories
    const fixedAccessories = this.props.product_accessories.getAll({
      product_id: this.props.stock.product_id,
      accessory_type: "fixed_pickup"
    })

    // Duplicate accessories based on quantity
    optionalAccessories.forEach((productAccessory) => {
      times(productAccessory.qty, (i) => optionalPickupAccessories.push({ ...productAccessory.accessory, index: i }))
    })

    // Duplicate fixed accessories based on quantity
    fixedAccessories.forEach((productAccessory) => {
      times(productAccessory.qty, (i) => fixedPickupAccessories.push({ ...productAccessory.accessory, index: i }))
    })

    // Add class if the product has accessories planned
    const linkClasses = ["optional-accessories", "accessories", "fa", "fa-inbox"]

    if (this.productHasAccessoriesPlanned()) {
      linkClasses.push("has-accessories")
    }

    return (
      <div className="row-tooltip-container" ref={this.elementRef}>
        {((optionalPickupAccessories.length > 0) || fixedPickupAccessories.length > 0 ) && (
          <a className={linkClasses.join(' ')} title="Plan extra accessoires in." onClick={this.handleToggle.bind(this)}></a>
        )}

        {((this.props.visible || this.state.visible) && (optionalPickupAccessories.length > 0 || fixedPickupAccessories.length > 0)) && (
          <div className="row-tooltip" onMouseLeave={this.handleClose.bind(this)}>
            <h2>{this.props.stock.name}</h2>

            { this.renderAccessoryList(optionalPickupAccessories, 'optional') }
            { this.renderAccessoryList(fixedPickupAccessories, 'fixed') }
          </div>
        )}
      </div>
    );
  }
}
/* eslint-enable react/jsx-no-bind */
