const React = require('react')
const createReactClass = require('create-react-class')
const { DragSource } = require('react-dnd')
const { getEmptyImage } = require('react-dnd-html5-backend')

const Planning = require('./planning')

const itemSource = {
  beginDrag (props) {
    return { planning: props.planning }
  }
}

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const DraggablePlanning = createReactClass({

  displayName: 'DraggablePlanning',

  componentDidMount () {
    this.props.connectDragPreview(getEmptyImage(), { captureDraggingState: true })
  },

  render () {
    const className = this.props.isDragging ? 'dragged' : ''
    const canDrag = this.props.canSwap && !this.props.planning.locked
    const planning = (
      <div>
        <Planning
          planning={this.props.planning}
          inOrder={this.props.inOrder}
          order={this.props.order}
          className={className}
          from={this.props.from}
          till={this.props.till}
        />
      </div>
    )

    if (!canDrag) {
      return planning
    } else {
      return this.props.connectDragSource(planning)
    }
  }
})

module.exports = DragSource('planning', itemSource, collect)(DraggablePlanning)
